import { cloneExportTemplate } from './cloneExportTemplate';
import { deleteExportTemplates } from './deleteExportTemplate';
import { loadExportTemplates } from './loadExportTemplates';
import { loadRankedColumnsByProject } from './loadRankedColumnsByProject';
import { saveExportTemplate } from './saveExportTemplate';

export const dataShim = {
    loadExportTemplates,
    loadRankedColumnsByProject,
    cloneExportTemplate,
    deleteExportTemplates,
    saveExportTemplate,
};
